.page-heading {
    color: #101828;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.study-heading {
    color: #121926;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.study-text {
    color: #475467;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    align-self: stretch;
    max-height: 258px;
    overflow: auto;
    padding-right: 5px !important;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        box-shadow: inset 0 0 5px #d9d9d9;
        border-radius: 4px;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        margin: 10px 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #adb5bd;
        cursor: pointer;
    }
}

.study-footer-detail-label {
    color: #202939;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    min-width: fit-content;

}

.study-footer-detail-text {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    min-width: fit-content;
}

.study-contact-link {
    color: #4BAAAC;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: underline;
}

.payment-fields-block {
    display: flex;
    flex-direction: row !important;
    padding: 8px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
}

.payment-fields-label {
    display: flex;
    height: 40px;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    min-width: 225px;
    color: #8C8C8C;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.payment-fields-value {
    color: #4A5568;
    font-weight: 600;
}

.outlined-btn {
    display: flex;
    height: 40px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
    border-radius: 8px;
    border: 1px solid #4A5568 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.payment-info-heading {
    color: #171923;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.payment-info-Sub {
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.payment-form-block {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input-label {
    color: #232323;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 18px;
}

.payment-input-input {
    color: #232323;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.page-heading {
    color: #101828;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.profil-info {
    color: #171923;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.conditions-heading {
    color: #171923;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
}

.user-info-data {
    font-family: Inter;
    color: #232323;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.profil-pic {
    color: #171923;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.profil-content {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.info-text {
    color: #4A5568;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.language-text {
    color: #171923;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.language-dropdown {
    display: flex;
    width: 320px;
    height: 32px;
    padding: 6px 8px 6px 12px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 12px;
}

%share-btn {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background-color: #319795 !important;
    width: fit-content;
    padding: 12px !important;
}

.save-btn {
    @extend %share-btn;
    width: 95px;
}

.download-btn {
    @extend %share-btn;
}

.conditions-content {
    display: flex;
    padding: 5px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.date-info {
    color: #4A5568;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.table-heading {
    border-top: 1px solid #E2E8F0;
    background: #F7FAFC;

}

.table-heading-title th {
    text-align: center;
    color: #4A5568;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: none;
}

.table-body-content td {
    text-align: center;
    border: none;
}

.rate-my-cell {
    color: #171923;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.history-table-date-cell {
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.history-table-btn {
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.history-text-btn {
    justify-content: center;
}

.history-checkbox-block>label {
    display: flex !important;
    padding: 12px 24px;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    color: #4A5568;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.history-feedback-titel {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.history-feedback-input {
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px !important;
    border: 1px solid #E2E8F0 !important;
}

.history-feedback-subtext {
    color: #344054 !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.send-feeback-btn {
    background-color: #319795;
}

.save-btn-disable {
    background-color: #99CECD;
    pointer-events: none;
    width: 95px;
}

.save-btn- {
    width: 95px;
    padding: 12px !important;
    background-color: #319795 !important;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.save-btn-:hover {
    background-color: #2a817f !important;
}

.success-badge {
    display: flex;
    padding: 2px 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px !important;
    border: 1px solid #81E6D9;
    background: #E6FFFA !important;
}

.contact-card {
    display: flex;
    padding: 20px 30px 20px 30px;
    flex-direction: column;
    gap: 15px;
    align-self: stretch;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.user-info-contact {}

.contact-heading {
    color: #171923;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.32px;
}

.contact-hint {
    color: #4A5568;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}


.help-small-text {
    color: #202939;
    font-family: Inter;
    font-size: 20px;
    font-style: bold;
    font-weight: 400;
    line-height: 24px;
}

.help-big-text {
    color: #202939;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.tax-info {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 10px;
    background: #F7FAFC;
}

.tax-info-text {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.dropdown-menu-list {
    max-height: 290px;
    overflow: scroll;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    border: unset;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        box-shadow: inset 0 0 5px #d9d9d9;
        border-radius: 4px;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        margin: 10px 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #adb5bd;
        cursor: pointer;
    }
}

.infoNotifyMessage {
    color: #4A5568;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.infoNotifyButton {
    color: #319795;
}

.table-container {
    &::-webkit-scrollbar {
        height: 3px !important;
        /* Controls the height of the horizontal scrollbar */
    }

    &::-webkit-scrollbar:horizontal {
        height: 3px !important;
        /* Explicitly target the horizontal scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
        box-shadow: inset 0 0 5px #d9d9d9 !important;
        border-radius: 4px !important;
        border-left: 2px solid transparent !important;
        border-right: 2px solid transparent !important;
        margin: 10px 0 !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px !important;
        background: #adb5bd !important;
        cursor: pointer !important;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        border-radius: 5px !important;
        background: #adb5bd !important;
        cursor: pointer !important;
    }
}

.profile-menu-item {
    gap: 8px !important;
}

.profile-menu-item span {
    margin: 0 !important;
    justify-content: flex-end;
}

.profile-menu-item img {
    width: 24px;
    height: 24px;
}

.font-variant-none {
    font-variant: none !important;
}

.steppanel-container {
    width: 80vw;
    max-width: 900px;
}

@media (max-width: 1600px) {

    /* Adjust breakpoint as needed */
    .steppanel-container {
        width: none !important;
        max-width: 800px !important;
    }
}

@media (max-width: 1200px) {

    /* Adjust breakpoint as needed */
    .steppanel-container {
        width: none !important;
        max-width: 600px !important;
    }
}

@media (max-width: 768px) {

    /* Adjust breakpoint as needed */
    .steppanel-container {
        min-width: 380px;
        padding: 0 !important;
        padding-top: 30px !important;
    }

    .steppanel-container .css-1fhprhc,
    .steppanel-container button {
        font-size: 14px !important;
    }

    .steppanel-container .css-5kb0a[data-status=complete],
    .steppanel-container .css-5kb0a[data-status=incomplete],
    .steppanel-container .css-792uno[data-status=complete],
    .steppanel-container .css-792uno[data-status=incomplete] {
        max-width: 38px !important;
    }
}


@media (max-width: 400px) {

    /* Adjust breakpoint as needed */
    .steppanel-container {
        min-width: 350px;
        padding: 0 !important;
        padding-top: 30px !important;
    }

    .steppanel-container .css-1fhprhc,
    .steppanel-container button {
        font-size: 12px !important;
    }

    .css-1hxlo4e {
        font-size: 12px !important;
    }

    .steppanel-container .css-kq5oaw[data-status=complete],
    .steppanel-container .css-kq5oaw[data-status=incomplete] {
        width: 20px;
    }

    .steppanel-container .css-5kb0a[data-status=complete],
    .steppanel-container .css-5kb0a[data-status=incomplete],
    .steppanel-container .css-792uno[data-status=complete],
    .steppanel-container .css-792uno[data-status=incomplete] {
        max-width: 30px !important;
    }

}

@media (max-width: 360px) {

    /* Adjust breakpoint as needed */
    .steppanel-container {
        min-width: 310px;
        padding: 0 !important;
        padding-top: 30px !important;
    }

    .steppanel-container .css-1fhprhc,
    .steppanel-container button {
        font-size: 10px !important;
    }

    .css-1hxlo4e {
        font-size: 10px !important;
    }

    .steppanel-container .css-kq5oaw[data-status=complete],
    .steppanel-container .css-kq5oaw[data-status=incomplete] {
        width: 20px;
    }

    .steppanel-container .css-5kb0a[data-status=complete],
    .steppanel-container .css-5kb0a[data-status=incomplete],
    .steppanel-container .css-792uno[data-status=complete],
    .steppanel-container .css-792uno[data-status=incomplete] {
        max-width: 20px !important;
    }

    .steppanel-container .css-5kb0a[data-status=active],
    .steppanel-container .css-792uno[data-status=active] {
        max-width: 120px !important;
    }

    .analyse-section-header .section-title {
        font-size: 16px !important;
    }
}

.small-blocks-scroll {
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        width: 6px;
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #2C7A7B;
        border-radius: 24px;
    }
}